import { useSigninCheck } from 'reactfire';
import { Helmet } from 'react-helmet-async';

import LinearProgress from '@mui/material/LinearProgress';

import { ScreenAppWaitlist } from '@flywheelautos/core/src/_screens/web/waitlist';
import { ScreenAppHome } from '@flywheelautos/core/src/_screens/web/home';
import { ScreenPublicHome } from '@flywheelautos/core/src/_screens/site/home';

export function PageWaitlist() {
  return (
    <>
      <Helmet>
        <title>Waitlist - Flywheel</title>
      </Helmet>
      <ScreenAppWaitlist />
    </>
  );
}

export function PageHome() {
  const { status, data: signInCheckResult } = useSigninCheck();

  if (status === 'loading') {
    return <LinearProgress />;
  }

  if (signInCheckResult.signedIn === true) {
    return <ScreenAppHome />;
  }
  return <ScreenPublicHome />;
}
