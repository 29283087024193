import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import {
  FirebaseAppProvider,
  FirestoreProvider,
  useFirebaseApp,
  AnalyticsProvider,
  AuthProvider,
  AppCheckProvider,
  useInitPerformance,
  StorageProvider,
} from 'reactfire';

import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import {
  getFirestore,
  connectFirestoreEmulator,
  enableIndexedDbPersistence,
} from 'firebase/firestore';
import { getAnalytics, logEvent, setCurrentScreen } from 'firebase/analytics';
import { getStorage } from 'firebase/storage';
import { logError } from '../log-error';

const firebaseConfig = {
  apiKey: 'AIzaSyDnqUuX2d6VqYNqlgNZetd1EzDVxeHgVJs',
  authDomain: 'accounts.flywheel.autos',
  projectId: 'flywheel-460bb',
  storageBucket: 'flywheel-460bb.appspot.com',
  messagingSenderId: '675285658316',
  appId: '1:675285658316:web:0d59aeca6cfcd7c4fcb069',
  measurementId: 'G-W9FG3KZN84',
};

const APP_CHECK_TOKEN = '6LdJD0AeAAAAAGtZ2IlGFCeAajDPr6pWg_dvTsKB';

function FirebaseServicesProviders({ appCheckToken, children }: any) {
  const app = useFirebaseApp();

  const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(appCheckToken || APP_CHECK_TOKEN),
    isTokenAutoRefreshEnabled: true,
  });

  const auth = getAuth(app);
  const firestore = getFirestore(app);
  const analytics = getAnalytics(app);
  const storage = getStorage(app);

  // performance
  useInitPerformance(async (firebaseApp) => {
    const { getPerformance } = await import('firebase/performance');
    return getPerformance(firebaseApp);
  });

  return (
    <AuthProvider sdk={auth}>
      <AppCheckProvider sdk={appCheck}>
        <FirestoreProvider sdk={firestore}>
          <StorageProvider sdk={storage}>
            <AnalyticsProvider sdk={analytics}>{children}</AnalyticsProvider>
          </StorageProvider>
        </FirestoreProvider>
      </AppCheckProvider>
    </AuthProvider>
  );
}

export function FirebaseProvider({ config, appCheckToken, children }: any) {
  return (
    <FirebaseAppProvider firebaseConfig={{ ...firebaseConfig, ...config }}>
      <FirebaseServicesProviders appCheckToken={appCheckToken}>
        {children}
      </FirebaseServicesProviders>
    </FirebaseAppProvider>
  );
}

// FirebaseEffects
export function FirebaseEffects() {
  // ------------------------------------------------------------
  // Emulator
  // Point Firestore + Auth to localhost if localhost
  const db = getFirestore();
  const auth = getAuth();

  useEffect(() => {
    try {
      if (window.location.hostname === 'localhost') {
        connectFirestoreEmulator(db, 'localhost', 8080);
        enableIndexedDbPersistence(db);
        connectAuthEmulator(auth, 'http://localhost:9099', {
          disableWarnings: true,
        });
      }
    } catch (error) {
      logError(error);
    }
  }, [db, auth]);

  // ------------------------------------------------------------
  // Analytics
  const location = useLocation();

  const app = useFirebaseApp();
  const analytics = getAnalytics(app);

  // Log page view
  useEffect(() => {
    const pagePath = location.pathname + location.search;
    setCurrentScreen(analytics, pagePath);
    logEvent(analytics, 'page_view', { pagePath });
  }, [location, analytics]);

  return null;
}
