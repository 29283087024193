import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import '../../../assets/css/_full-height.css';

import { WebNavbar } from '../../ui/web/navbar';

export function ScreenAppWaitlist() {
  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <WebNavbar />

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexGrow: 1,
        }}
      >
        <Container maxWidth="xs" sx={{ my: 3 }}>
          <Typography
            variant="h3"
            color="primary"
            sx={{ fontWeight: 900, mb: -1.6, ml: -4, zIndex: 3 }}
          >
            Coming Soon
          </Typography>
          <Paper
            variant="outlined"
            sx={{ p: 2.5, zIndex: 4, position: 'relative' }}
          >
            <Typography paragraph>
              Thank you for your interest in Flywheel.
            </Typography>

            <Typography paragraph>
              We are currently working on the first version of the web app and
              you will be the first to know when it is ready.
            </Typography>

            <Typography paragraph>
              If you would like to get access sooner, reach out to us at{' '}
              <a href="mailto:beta@flyhwheel.autos">beta@flywheel.autos</a>
            </Typography>

            <link
              href="https://fonts.googleapis.com/css2?family=Square+Peg&display=swap"
              rel="stylesheet"
            />
            <Typography
              sx={{
                fontFamily: '"Square Peg", cursive',
                fontSize: 35,
              }}
            >
              Flywheel Team
            </Typography>
          </Paper>
        </Container>
      </Box>
    </Box>
  );
}
