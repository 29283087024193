import { Link as RouterLink } from 'react-router-dom';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';

import Logo from '../../../assets/img/logo/icon-color.svg';

import '../../../assets/css/_full-height.css';

export function ScreenAuthLayout({ children }: any) {
  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Container maxWidth="xs" sx={{ my: 4 }}>
        {/* Logo */}
        <Box sx={{ mb: 3, textAlign: 'center' }}>
          <img alt="logo" src={Logo} style={{ width: '50px' }} />
        </Box>

        {/* Paper */}
        <Paper variant="outlined" sx={{ p: 3 }}>
          {children}
        </Paper>

        {/* Privacy and Terms of Service */}
        <Box
          sx={{
            textAlign: 'center',
            mt: 3,
            '& a': {
              color: 'text.secondary',
              textDecoration: 'none',
            },
          }}
        >
          <Link
            component={RouterLink}
            to="/privacy-policy"
            target="_blank"
            variant="body2"
          >
            Privacy Policy
          </Link>
          {' . '}
          <Link
            component={RouterLink}
            to="/terms-of-service"
            target="_blank"
            variant="body2"
          >
            Terms of Service
          </Link>
        </Box>
      </Container>
    </Box>
  );
}
