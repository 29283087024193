import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { ScreenInfoLayout } from './_layout';

import Logo from '../../../assets/img/logo/icon-color-circle.svg';

export function ScreenInfoLoading() {
  return (
    <ScreenInfoLayout>
      <Box sx={{ m: 1, position: 'relative' }}>
        <img src={Logo} alt="logo" style={{ width: '56px' }} />
        <CircularProgress
          size={68}
          sx={{
            position: 'absolute',
            top: -6,
            left: -6,
            zIndex: 1,
          }}
        />
      </Box>
    </ScreenInfoLayout>
  );
}
