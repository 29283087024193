import { PublicHeaderNavbar } from '../../ui/site/header/navbar';

export function ScreenPublicHome() {
  return (
    <>
      <PublicHeaderNavbar />
      Hero
    </>
  );
}
