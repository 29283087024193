import { getAuth, signOut } from 'firebase/auth';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import LogoutIcon from '@mui/icons-material/Logout';

import { ScreenInfoLayout } from './_layout';

export function ScreenInfoForbidden() {
  const handleSignOut = () => {
    signOut(getAuth());
  };

  return (
    <ScreenInfoLayout>
      <Paper sx={{ p: 2 }} variant="outlined">
        <Alert severity="info" sx={{ mb: 2, textAlign: 'left' }}>
          admin.flywheel.autos is used for Workspace accounts only. Regular
          accounts cannot be used to sign in to admin.flywheel.autos.
        </Alert>
        <Button
          fullWidth
          variant="contained"
          disableElevation
          onClick={handleSignOut}
          startIcon={<LogoutIcon />}
        >
          Sign Out
        </Button>
      </Paper>
    </ScreenInfoLayout>
  );
}
