import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import HomeIcon from '@mui/icons-material/Home';

import { ScreenInfoLayout } from './_layout';

import NotFoundIllustration from '../../../assets/img/illustrations/not-found-error-rafiki.svg';

export function ScreenInfoNotFound() {
  const navigate = useNavigate();
  return (
    <ScreenInfoLayout>
      <img
        src={NotFoundIllustration}
        alt="not found illustration"
        style={{ maxWidth: '100%' }}
      />
      <Typography variant="h5">404 Error</Typography>
      <Typography variant="h3" gutterBottom>
        Not Found
      </Typography>
      <Typography variant="body1">
        We cannot find the page you are looking for...
      </Typography>
      <Button
        variant="contained"
        sx={{ mt: 3 }}
        disableElevation
        onClick={() => navigate('/')}
        startIcon={<HomeIcon />}
      >
        Go Home
      </Button>
    </ScreenInfoLayout>
  );
}
