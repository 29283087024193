import React from 'react';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

export function OfflineEffects() {
  React.useEffect(() => {
    serviceWorkerRegistration.register({
      onUpdate: (registration) => {
        registration.waiting?.postMessage({ type: 'SKIP_WAITING' });
        window.location.reload();
      },
    });
  });
  return null;
}
