import { useNavigate } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';

import { logError } from '../../_lib/log-error';

import { ScreenInfoLayout } from './_layout';

export function ScreenInfoError({ error }: any) {
  const navigate = useNavigate();

  if (error) logError(error);

  return (
    <ScreenInfoLayout>
      <Container maxWidth="xs">
        <Paper sx={{ p: 2 }} variant="outlined">
          <Alert severity="error">Something went wrong</Alert>
          <Button
            variant="contained"
            disableElevation
            fullWidth
            sx={{ mt: 2, mb: 1.5 }}
            onClick={() => window.location.reload()}
          >
            Reload Page
          </Button>
          <Button
            variant="contained"
            color="info"
            disableElevation
            fullWidth
            onClick={() => navigate('/')}
          >
            Go to Home Page
          </Button>
        </Paper>
      </Container>
    </ScreenInfoLayout>
  );
}
