import React from 'react';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { SxProps } from '@mui/material';

export interface DialogTitleProps {
  id?: string;
  children?: React.ReactNode;
  onClose: () => void;
  sx?: SxProps;
}

export function DialogTitle(props: DialogTitleProps) {
  const { children, onClose, sx, ...other } = props;

  return (
    <MuiDialogTitle sx={{ m: 0, p: 2, ...sx }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
}

DialogTitle.defaultProps = {
  id: undefined,
  children: undefined,
  sx: {},
};

export default DialogTitle;
