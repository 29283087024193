import CssBaseline from '@mui/material/CssBaseline';
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
  StyledEngineProvider,
} from '@mui/material/styles';

import '@fontsource/inter/latin.css';

const theme = createTheme({
  palette: {
    primary: {
      main: '#00ADFF',
      contrastText: '#fff',
    },
    secondary: {
      main: '#00F1FF',
    },
  },
  typography: {
    fontFamily: '"Inter", sans-serif',
  },
});

export function ThemeProvider({ children }: any) {
  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
}
