import { useUser } from 'reactfire';
import { Link as RouterLink } from 'react-router-dom';

import { getAuth, signOut } from 'firebase/auth';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import LogoutIcon from '@mui/icons-material/Logout';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';

import { DialogTitle } from '../../../../common/dialog-title';

import Logo from '../../../../../../assets/img/logo/text-color.svg';

export function WebNavbarAvatarDialog({ open, setOpen }: any) {
  const auth = getAuth();
  const { status, data: user } = useUser();

  const handleSignOut = () => {
    signOut(auth);
  };

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <DialogTitle onClose={() => setOpen(false)} sx={{ textAlign: 'center' }}>
        <img alt="logo" src={Logo} style={{ maxWidth: '100%', height: 24 }} />
      </DialogTitle>

      {/* account */}
      <Box sx={{ p: 2, pt: 0, textAlign: 'center' }}>
        {/* loading */}
        {status === 'loading' && (
          <>
            <Skeleton
              variant="circular"
              width={60}
              height={60}
              sx={{ mx: 'auto' }}
            />
            <Typography variant="h4" sx={{ mt: 1, px: 3 }}>
              <Skeleton />
            </Typography>
          </>
        )}

        {/* loaded */}
        {status !== 'loading' && user && (
          <>
            {/* avatar */}
            <Avatar
              alt={user.displayName || 'avatar'}
              src={user.photoURL || undefined}
              sx={{ mx: 'auto' }}
            />

            {/* display name + email */}
            <Typography variant="h5" sx={{ mt: 1, px: 3 }}>
              {user.displayName}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ mt: 0.5, px: 3 }}
            >
              {user.email}
            </Typography>

            {/* TODO: Manage Account */}
          </>
        )}
      </Box>

      <DialogContent dividers>
        <Button
          variant="outlined"
          fullWidth
          onClick={handleSignOut}
          startIcon={<LogoutIcon />}
          sx={{ textTransform: 'none' }}
        >
          Sign Out
        </Button>
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'center', py: 2 }}>
        <Box
          sx={{
            textAlign: 'center',
            '& a': {
              color: 'text.secondary',
              textDecoration: 'none',
            },
          }}
        >
          <Link
            component={RouterLink}
            to="/privacy-policy"
            target="_blank"
            variant="body2"
          >
            Privacy Policy
          </Link>
          {' . '}
          <Link
            component={RouterLink}
            to="/terms-of-service"
            target="_blank"
            variant="body2"
          >
            Terms of Service
          </Link>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
