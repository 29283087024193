import Button from '@mui/material/Button';

import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

import { GoogleIcon } from '../icons/google';

import { logError } from '../../_lib/log-error';

export function AuthGoogleLogin({ loading, setLoading }: any) {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();

  const handleClick = () => {
    setLoading(true);
    signInWithPopup(auth, provider)
      .catch((e) => {
        logError(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Button
      fullWidth
      variant="outlined"
      startIcon={<GoogleIcon />}
      sx={{
        textTransform: 'none',
        color: '#518EF8',
        borderColor: '#518EF8',
        borderWidth: 1.5,
        '&:hover': {
          borderWidth: 1.5,
          color: 'primary.main',
        },
      }}
      disabled={loading}
      onClick={handleClick}
    >
      Sign in with Google
    </Button>
  );
}
