import React from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import LinearProgress from '@mui/material/LinearProgress';

import { OfflineEffects } from '../offline/effects';
import { FirebaseEffects, FirebaseProvider } from './firebase';
import { ThemeProvider } from './theme';

// import './i18n';  // TODO: i18n

import { ScreenInfoError } from '../../_screens/_info/error';

export function CommonProviders({
  firebaseConfig,
  headingFont,
  children,
}: any) {
  return (
    <React.Suspense fallback={<LinearProgress />}>
      <ErrorBoundary FallbackComponent={ScreenInfoError}>
        <BrowserRouter>
          <FirebaseProvider config={firebaseConfig}>
            <ThemeProvider headingFont={headingFont}>
              <HelmetProvider>{children}</HelmetProvider>
              <FirebaseEffects />
              <OfflineEffects />
            </ThemeProvider>
          </FirebaseProvider>
        </BrowserRouter>
      </ErrorBoundary>
    </React.Suspense>
  );
}

export default CommonProviders;
