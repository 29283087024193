import { Route, Routes } from 'react-router-dom';

import {
  PrivateRoute,
  RedirectRoute,
  ReversePrivateRoute,
} from '@flywheelautos/core/src/_lib/route-utils';
import { ScreenInfoNotFound } from '@flywheelautos/core/src/_screens/_info/not-found';

import { PageHome, PageWaitlist } from './home';
import { PageAuthLogin } from './auth';

export function PagesRoutes() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <PageHome />
          </PrivateRoute>
        }
      />

      <Route
        path="/waitlist"
        element={
          <PrivateRoute>
            <PageWaitlist />
          </PrivateRoute>
        }
      />

      <Route
        path="/login"
        element={
          <ReversePrivateRoute>
            <PageAuthLogin />
          </ReversePrivateRoute>
        }
      />

      {/* impressum */}
      <Route
        path="/privacy-policy"
        element={
          <RedirectRoute
            url="https://bit.ly/3v6MPlA"
            title="Privacy Policy - Flywheel"
          />
        }
      />
      <Route
        path="/terms-of-service"
        element={
          <RedirectRoute
            url="https://bit.ly/3rf42Ir"
            title="Terms of Service - Flywheel"
          />
        }
      />
      <Route
        path="/disclaimer"
        element={
          <RedirectRoute
            url="https://bit.ly/38zn2KZ"
            title="Disclaimer - Flywheel"
          />
        }
      />

      {/* 404 */}
      <Route path="*" element={<ScreenInfoNotFound />} />
    </Routes>
  );
}
