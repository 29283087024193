import React from 'react';
import { useUser } from 'reactfire';

import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import Avatar from '@mui/material/Avatar';

import { WebNavbarAvatarDialog } from './dialog';

export function WebNavbarAvatar() {
  const { status, data: user } = useUser();

  const [openAvatar, setOpenAvatar] = React.useState(false);

  return (
    <>
      <WebNavbarAvatarDialog open={openAvatar} setOpen={setOpenAvatar} />
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        color="primary"
        onClick={() => setOpenAvatar(true)}
      >
        {status === 'loading' ? (
          <CircularProgress size={24} />
        ) : (
          <Avatar
            alt={user?.displayName || undefined}
            src={user?.photoURL || undefined}
            sx={{
              width: 40,
              height: 40,
              borderWidth: 2,
              borderColor: 'primary.main',
              borderStyle: 'solid',
              bgcolor: 'primary.dark',
            }}
          >
            {user?.displayName ? user.displayName[0] : ''}
            {!!user?.displayName &&
              user.displayName.split(' ').length > 1 &&
              user.displayName.split(' ')[1][0]}
          </Avatar>
        )}
      </IconButton>
    </>
  );
}
