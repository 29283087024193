import { Helmet } from 'react-helmet-async';

import { ScreenAuthLogin } from '@flywheelautos/core/src/_screens/auth/login';

export function PageAuthLogin() {
  return (
    <>
      <Helmet>
        <title>Login - Flywheel</title>
      </Helmet>
      <ScreenAuthLogin />
    </>
  );
}
