import React from 'react';

import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { ScreenAuthLayout } from './_layout';
import { AuthGoogleLogin } from '../../ui/auth/google-login';
import { AuthFormInput } from '../../ui/auth/form-input';

export function ScreenAuthLogin() {
  const [loading, setLoading] = React.useState(false);
  return (
    <ScreenAuthLayout>
      <Typography variant="body1" sx={{ mb: 3 }}>
        We suggest using an email address/account that{' '}
        <strong>has multi-factor authentication enabled</strong>.
      </Typography>
      <Stack spacing={1.5}>
        <AuthGoogleLogin loading={loading} setLoading={setLoading} />
      </Stack>
      <Divider sx={{ my: 2.5 }}>OR</Divider>
      <AuthFormInput loading={loading} setLoading={setLoading} />
    </ScreenAuthLayout>
  );
}
