import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import { WebNavbarAvatar } from './actions/avatar';

import Logo from '../../../../assets/img/logo/text-color.svg';

export function WebNavbarAppbar() {
  return (
    <AppBar position="static" elevation={0} color="transparent">
      <Toolbar>
        <img alt="logo" src={Logo} style={{ height: '30px' }} />
        <Box sx={{ flexGrow: 1 }} />
        <WebNavbarAvatar />
      </Toolbar>
    </AppBar>
  );
}
