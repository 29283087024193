import React from 'react';

import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import AutoAwesomeOutlined from '@mui/icons-material/AutoAwesomeOutlined';

export function AuthFormInput({ loading, setLoading }: any) {
  const [loginId, setLoginId] = React.useState('');

  const [alertOpen, setAlertOpen] = React.useState('info');

  // check if input is phone number or email
  const getLoginIdType = (text: string) => {
    const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;
    const emailRegex = /^\S+@\S+\.\S+$/;
    const coinToss = Math.floor(Math.random() * 2) === 0 ? 'phone' : 'email';

    if (phoneRegex.test(text) && emailRegex.test(text)) {
      return coinToss;
    }

    if (phoneRegex.test(text)) {
      return 'phone';
    }
    if (emailRegex.test(text)) {
      return 'email';
    }

    return coinToss;
  };

  const handleClick = () => {
    // setLoading(true);
    // TODO: add login handler
    // TODO: check if phone number
  };

  return (
    <>
      <Stack spacing={2}>
        <TextField
          label="Email / Phone Number"
          value={loginId}
          onChange={(e) => setLoginId(e.target.value)}
          fullWidth
        />
        <LoadingButton
          loading={loading}
          onClick={handleClick}
          sx={{
            textTransform: 'none',
          }}
          disableElevation
          fullWidth
          variant="contained"
        >
          Sign in with Email / Phone Number
        </LoadingButton>

        {/* Alerts */}
        {alertOpen === 'info' && (
          <Alert severity="info" icon={<AutoAwesomeOutlined />}>
            We will send you a magic code for a password-free sign in.
          </Alert>
        )}
        {alertOpen === 'error' && (
          <Alert severity="error">
            Something went wrong. Please try again.
          </Alert>
        )}
        {alertOpen === 'success' && (
          <Alert severity="success">Check your email for sign in link.</Alert>
        )}
      </Stack>

      {/* TODO: confirm phone dialog */}
    </>
  );
}
