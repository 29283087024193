import { CommonProviders } from '@flywheelautos/core/src/_lib/providers/common';

import { PagesRoutes } from './pages/_routes';

function App() {
  return (
    <CommonProviders>
      <PagesRoutes />
    </CommonProviders>
  );
}

export default App;
